















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DevicesAction, DevicesActionType } from '@/store/modules/devices/types';
import { AppAction, AppActionType, AppState } from '@/store/modules/app/types';
import CreatorCardTabbedList from './CreatorCardTabbedList/CreatorCardTabbedList.vue';
import CreatorCardAssignTable from './CreatorCardAssignTable/CreatorCardAssignTable.vue';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';
import { CreatorTabs, CreatorTabsTypes } from '@/constants/main_card_inner_tabs';
import { TranslateResult } from 'vue-i18n';

const app = namespace('app');
const devices = namespace('devices');
const admin = namespace('admin');

interface CreatorTab {
  name: TranslateResult;
  value: CreatorTabsTypes
}

@Component({
  components: {
    CreatorCardTabbedList,
    CreatorCardAssignTable,
  },
})
/**
 * @group Creator Card
 * Creator tab content
 */
export default class CreatorCard extends Vue {
  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @app.State
  selectedCreatorTab!: AppState['selectedCreatorTab'];

  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB']

  @app.Action(AppAction.SetSelectedCreatorTab)
  setSelectedCreatorTab!: AppActionType['SET_SELECTED_CREATOR_TAB']

  @app.Action(AppAction.SetMapSelectionEnabled)
  setMapSelectionEnabled!: AppActionType['SET_MAP_SELECTION_ENABLED']

  @devices.Action(DevicesAction.SetInsidePolygonMarkers)
  setInsidePolygonMarkers!: DevicesActionType['SET_INSIDE_POLYGON_MARKERS'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  selectedTab: CreatorTabsTypes = CreatorTabs.Circuits;

  get creatorTabs(): Array<CreatorTab> {
    return [
      { name: this.$tc('main.circuit', 2), value: CreatorTabs.Circuits },
      { name: this.$tc('main.group', 2), value: CreatorTabs.Groups },
    ];
  }

  get assignListElements() {
    return this.selectedTab === CreatorTabs.Circuits ? 'lamps' : 'circuits';
  }

  get permissionCheckCircuitsWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'circuits');
    return getPermissions.write;
  }

  selectOnMap() {
    this.setInsidePolygonMarkers([]);
    this.setMapSelectionEnabled(true);
    this.setSelectedTab(null);
  }

  mounted() {
    this.selectedTab = this.selectedCreatorTab ?? CreatorTabs.Circuits;
  }

  beforeDestroy() {
    this.setSelectedCreatorTab(CreatorTabs.Circuits);
  }
}
