
import Vue, { PropType } from 'vue';
import AsyncLoading from '@/components/base/AsyncLoading.vue';
import AsyncError from '@/components/base/AsyncError.vue';

const lampsTable: any = () => ({
  component: import('./AssignTableLamps/AssignTableLamps.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const circuitsTable: any = () => ({
  component: import('./AssignTableCircuits/AssignTableCircuits.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

export default Vue.extend({
  functional: true,
  name: 'CreatorCardAssignTable',
  props: {
    elements: {
      type: String as PropType<'lamps' | 'circuits'>,
      required: true,
    }
  },
  render(createElement, { props }) {
    const appropriateList = props.elements === 'lamps' ? lampsTable : circuitsTable;

    return createElement(appropriateList);
  }
});
