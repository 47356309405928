











import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AsyncLoading from '@/components/base/AsyncLoading.vue';
import AsyncError from '@/components/base/AsyncError.vue';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';
import { CreatorTabs, CreatorTabsTypes } from '@/constants/main_card_inner_tabs';

const admin = namespace('admin');

const circuitsTab: any = () => ({
  component: import('./EditableCircuitsList/EditableCircuitsList.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const groupsTab: any = () => ({
  component: import('./EditableGroupsList/EditableGroupsList.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

@Component({
  components: {
    circuitsTab,
    groupsTab
  }
})
export default class CreatorCardTabbedList extends Vue {
  @Prop(String) readonly selectedTab!: CreatorTabsTypes;

  @Prop(Array) readonly tabs!: Array<{ name: string, value: CreatorTabsTypes }>

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  get currentTab() {
    return this.selectedTab === CreatorTabs.Circuits ? 'circuitsTab' : 'groupsTab';
  }

  get permissionCheckWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'circuits');
    return getPermissions.write;
  }

  get permissionCheckDelete() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'circuits');
    return getPermissions.delete;
  }
}
