import { render, staticRenderFns } from "./CreatorCardTabbedList.vue?vue&type=template&id=1548f157&scoped=true&"
import script from "./CreatorCardTabbedList.vue?vue&type=script&lang=ts&"
export * from "./CreatorCardTabbedList.vue?vue&type=script&lang=ts&"
import style0 from "./CreatorCardTabbedList.vue?vue&type=style&index=0&id=1548f157&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1548f157",
  null
  
)

export default component.exports